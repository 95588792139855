<template>
  <v-carousel
    v-model="currentItemIndex"
    hide-delimiters
    class="modal-view-photo"
  >
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>

export default {
  props: {
    items: Array,
    index: Number
  },
  data() {
    return {
      currentItemIndex: this.index
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-view-photo {
  ::v-deep {
    .v-window__next, {
      right: 0;
    }
  }
}
</style>
